import * as React from 'react';
import Link from '@mui/material/Link';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

const backgroundImage =
  'https://s3.us-east-2.amazonaws.com/images.cooingestate.com/admin/property_image/image/13729/q9EstyR3JVNnixMowuPW6LiT1sQV7jC09.jpg';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Upgrade your Vacation
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        {'Enjoy your summer from an unrivaled coastal destination in a safe and comfy home at '}
        <Link underline="none" href="https://www.emaarmisr.com/en/properties/marina-front-residences" rel="sponsored" title="Marassi Marina" sx={{ color: 'secondary.main' }}>
          Marassi Marina
        </Link>
        {'.'}
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="https://airbnb.com/h/marassi-marina-3br-condo"
        sx={{ minWidth: 200 }}
      >
        Book Your Stay
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Become a guest
      </Typography>
    </ProductHeroLayout>
  );
}
